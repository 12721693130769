<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1 v-if="accountid">
          Edit Account
        </h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="success"
          @click="submitForm('ruleForm')"
        >
          Save
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!accountid"
          title="Account Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="accountid"
          title="Account Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
    </el-col>
  </el-row>

  <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition"
  >
    <div class="panel panel-default">
      <div
        class="panel-title"
        style="padding-bottom: 25px;margin-bottom: 20px;"
      >
        <i class="fa fa-bars" /> Account Detail
      </div>
      <el-tabs type="border-card">
         <el-tab-pane label="Login Info">
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Username"
                prop="username"
              >
                <el-input
                  v-model="data.username"
                  type="text"
                  readonly
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Password"
                prop="password"
              >
                <el-input
                  v-model="data.password"
                  type="password"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="passwordConfirm">
            <el-alert
              title="Password and Confirm Password did not match."
              type="error"
              effect="dark"
            />
          </div>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Confirm"
                prop="confirm"
              >
                <el-input
                  v-model="data.confirm"
                  type="password"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Logout From All Devices"
                prop="logout_all"
              >
                <el-checkbox
                  v-model="data.logout_all"
                  size="small"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-form>
</template>
<script>
import AccountService from "@/api/account.service";
  export default {
    data() {
      
      var checkPassword = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Password.'));
        } else {
          callback();
        }
      };
      var checkUsername = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Username.'));
        } else {
          callback();
        }
      };
      var checkConfirm = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Confirm.'));
        } else {
          callback();
        }
      };
      return {
        showAlertAdded: false,
        passwordConfirm:false,
        labelPosition: 'top',
        accountid: null,
        iflogin:this.$route.query.iflogin,
       
        data: {          
          username:null,
          password:null,
          confirm:null,
          logout_all:null,
        },
        rules: {
           password: [
            { validator: checkPassword, trigger: 'blur' }
          ],
          username: [
            { validator: checkUsername, trigger: 'blur' }
          ],
           confirm: [
            { validator: checkConfirm, trigger: 'blur' }
          ],
        }
      };
    },

	mounted() {
    this.getrealtor();
  },
   methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if(this.data.password!==this.data.confirm){
              this.passwordConfirm=true;
          }else if (valid && !this.accountid) {
             return AccountService.add(this.data).then(response => {
                this.showAlertAdded=true;
              });
          } else if (valid && this.accountid) {
           
            return AccountService.update(this.data).then(response => {
              this.showAlertAdded=true;
              if(this.data.logout_all===true){
                localStorage.removeItem('user');
                this.$router.push("/logout");
             }
            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
	getrealtor(){
    return AccountService.get().then(response => {
        this.data = response.data.data[0];
        this.accountid =response.data.data[0].id;
      });
	},

    }
  }
</script>
<style lang="scss" scoped>
 .panel-title{
      border-bottom: 1px solid #9a83835c;
      padding-bottom: 10px;
      margin-bottom: 10px; 
      text-align: left;
  }
 .titledate {
    color: #2E7D32;
    font-size: 18px;
    padding: 10px 0px 0px 0px;
}
.panel {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}
.el-row {
    margin-bottom: 0px !important;
}
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
.el-select{
     width: 100%;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
.el-checkbox{
  float: left;
}
</style>
